import { Box, Input, Select, Text } from "@chakra-ui/react"
import React, { useContext, useEffect, useState } from "react"
import { VomuxButton } from "../shared/vomux_button"
import {
  VomuxTextAttention,
  VomuxTextStandard,
  VomuxTextTitle,
} from "../shared/vomux_text"
import SignUpBanner from "../../images/sign_in_comapnies_banner.svg"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { navigate } from "gatsby"
import { ApiContext, UserContext } from "../Root"
import { ApiClient } from "../../services/api"
import validator from "email-validator"
import {
  sendAmplitudeEvent,
  setAmplitudeUserId,
} from "../../services/amplitude"
import { Agreements } from "../shared/agreements"
import { getUuidFromPath } from "../../utils/path"
import { useDelayedAnalyticsEventOnLand } from "../../hooks/analytics"
import { AnalyticsEvents } from "../../services/types"

const hdyhauOptions = {
  producthunt: "ProductHunt",
  linkedin: "LinkedIn",
  friend: "Friend",
  blog: "Blog",
  other: "Other",
}

export const SignUpForm: React.FC = props => {
  const api = useContext(ApiContext)
  const user = useContext(UserContext)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [hdyhau, setHdyhau] = useState("")
  const [is_nontechnical_cofounder, setNonTechnicalCofounder] =
    useState(undefined)
  const [is_hiring_software_engineers, setHiringSoftwareEngineers] =
    useState(undefined)
  const [emailError, setEmailError] = useState("")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [hdyhauErr, setHdyhauErr] = useState("")
  const [nonTechnicalCofounderErr, setNonTechnicalCofounderErr] = useState("")
  const [hiringSoftwareEngineersErr, setHiringSoftwareEngineersErr] =
    useState("")
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.signup_form_landed)

  async function userUe() {
    if (user.data.account_id) {
      const session_invite_id = getUuidFromPath()
      if (session_invite_id) {
        const redeemedInvite = await api.client.redeemSessionInvite({
          session_invite_id,
        })
        if (!redeemedInvite.success) {
          sendAmplitudeEvent(
            AnalyticsEvents.signup_form_session_invite_redemption_failed,
            {
              session_invite_id,
              account_id: user.data.account_id,
              message: redeemedInvite.message,
            }
          )
          alert(redeemedInvite.message)
          navigate("/home")
          return
        }
        sendAmplitudeEvent(
          AnalyticsEvents.signup_form_session_invite_redeemed,
          {
            session_invite_id,
            account_id: user.data?.account_id,
          }
        )
        const sessionInvite = await api.client.getSessionInviteById(
          session_invite_id
        )
        if (!sessionInvite) {
          alert("Session invite not found")
          return
        }
        sendAmplitudeEvent(AnalyticsEvents.signup_form_redirected, {
          session_invite_id,
          session_id: sessionInvite.session_id,
          account_id: user.data?.account_id,
          location: "/interview",
        })

        navigate(`/interview/${sessionInvite.session_id}`)
        return
      }
      const companies = await api.client.getCompaniesForAccount()
      if (companies && companies.length > 0) {
        sendAmplitudeEvent(AnalyticsEvents.signup_form_redirected, {
          account_id: user.data?.account_id,
          location: "/home",
        })
        navigate("/home")
        return
      }
      sendAmplitudeEvent(AnalyticsEvents.signup_form_redirected, {
        session_invite_id,
        account_id: user.data?.account_id,
        location: "create_company",
      })
      navigate("/create_company")
    }
  }

  useEffect(() => {
    userUe()
  }, [user.data?.account_id])

  const handleSignup = async () => {
    if (!firstName) {
      setFirstNameError("First name is required.")
      return
    }
    if (!lastName) {
      setLastNameError("Last name is required")
      return
    }
    if (!validator.validate(email)) {
      setEmailError("Email is invalid.")
      return
    }
    if (typeof is_nontechnical_cofounder === "undefined") {
      setNonTechnicalCofounderErr("Non-technical cofounder info is required.")
      return
    }
    if (typeof is_hiring_software_engineers === "undefined") {
      setHiringSoftwareEngineersErr("Hiring engineers info is required.")
      return
    }
    if (!hdyhau) {
      setHdyhauErr("How did you hear about Vomux is required.")
      return
    }

    sendAmplitudeEvent(AnalyticsEvents.signup_form_signup_attempted, {
      email,
      first_name: firstName,
      last_name: lastName,
    })

    const signupRes = await api.client.createAccount({
      first_name: firstName,
      last_name: lastName,
      email,
      account_password: password,
      hdyhau,
      is_nontechnical_cofounder,
      is_hiring_software_engineers,
    })
    if (!signupRes || !signupRes.data?.token) {
      alert("issue signing up")
      sendAmplitudeEvent(AnalyticsEvents.signup_form_signup_failed, {
        email,
        first_name: firstName,
        last_name: lastName,
      })
      return
    }
    if (typeof window !== "undefined") {
      window.localStorage.setItem("token", signupRes.data.token)
    }
    sendAmplitudeEvent(AnalyticsEvents.signup_form_signup_succeeded, {
      email,
      first_name: firstName,
      last_name: lastName,
      account_id: signupRes.created_id,
    })
    api.setApi(new ApiClient(signupRes.data.token))
    user.setUser({
      account_id: signupRes.created_id,
      email: email,
      first_name: firstName,
      last_name: lastName,
      is_confirmed: false,
      created_at: new Date(),
    })
    setAmplitudeUserId(signupRes.created_id)
  }

  return (
    <Box
      zIndex="2"
      height={{ base: "75%", md: "85%" }}
      width={{ base: "80%", md: "60%", lg: "40%" }}
      borderRadius="10px"
      backgroundColor="white"
      boxShadow="0px 10px 8px #bbb"
      display="flex"
      justifyContent="center"
      marginTop={{ base: "6rem", md: "0" }}
      overflowY="scroll"
    >
      <Box
        width="80%"
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        marginTop="3rem"
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
          mt={{ base: "12rem", md: "12rem" }}
        >
          <VomuxTextStandard fontSize="1rem">
            Have an account?
          </VomuxTextStandard>
          <VomuxTextAttention
            onClick={() => {
              navigate("/login")
            }}
            cursor="pointer"
            fontSize="1rem"
          >
            Sign In
          </VomuxTextAttention>
        </Box>
        <VomuxTextTitle>Sign Up</VomuxTextTitle>
        <Text mt="1rem">First name</Text>
        <Input
          padding=".5rem"
          placeholder="First name"
          borderRadius="10px"
          width="100%"
          value={firstName}
          onChange={e => {
            setFirstNameError("")
            setFirstName(e.target.value)
          }}
        ></Input>
        <Text mt="1rem">Last name</Text>
        <Input
          padding=".5rem"
          placeholder="Last name"
          borderRadius="10px"
          width="100%"
          value={lastName}
          onChange={e => {
            setLastNameError("")
            setLastName(e.target.value)
          }}
        ></Input>
        <Text mt="1rem">Work email</Text>
        <Input
          padding=".5rem"
          placeholder="Work email"
          borderRadius="10px"
          width="100%"
          value={email}
          onChange={e => {
            setEmailError("")
            setEmail(e.target.value)
          }}
        ></Input>
        <Text mt="1rem">Password</Text>
        <Input
          padding=".5rem"
          placeholder="Password"
          borderRadius="10px"
          width="100%"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        ></Input>
        <Text mt="1rem">Are you a non-technical cofounder?</Text>
        <Select
          onChange={e => {
            setNonTechnicalCofounderErr(undefined)
            if (e.target.value === "true") {
              setNonTechnicalCofounder(true)
              return
            }
            if (e.target.value === "false") {
              setNonTechnicalCofounder(false)
              return
            }
            setNonTechnicalCofounder(undefined)
          }}
        >
          <option></option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Text mt="1rem">Is your team hiring software engineers?</Text>
        <Select
          onChange={e => {
            setHiringSoftwareEngineersErr(undefined)
            if (e.target.value === "true") {
              setHiringSoftwareEngineers(true)
              return
            }
            if (e.target.value === "false") {
              setHiringSoftwareEngineers(false)
              return
            }
            setHiringSoftwareEngineers(undefined)
          }}
        >
          <option></option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        <Text mt="1rem">How did you hear about Vomux?</Text>
        <Select
          onChange={e => {
            setHdyhauErr("")
            setHdyhau(e.target.value)
          }}
        >
          <option></option>
          {Object.keys(hdyhauOptions).map(how => {
            return <option value={how}>{hdyhauOptions[how]}</option>
          })}
        </Select>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={emailError ? "flex" : "none"}
        >
          Please enter a valid email.
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={firstNameError ? "flex" : "none"}
        >
          Please enter a first name.
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={lastNameError ? "flex" : "none"}
        >
          Please enter a last name.
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={nonTechnicalCofounderErr ? "flex" : "none"}
        >
          {nonTechnicalCofounderErr}
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={hiringSoftwareEngineersErr ? "flex" : "none"}
        >
          {hiringSoftwareEngineersErr}
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={hdyhauErr ? "flex" : "none"}
        >
          {hdyhauErr}
        </VomuxTextStandard>
        <VomuxButton
          borderColor={COLORS_PRIMARY.LIGHT_BLUE}
          padding=".5rem"
          backgroundColor={COLORS_PRIMARY.LIGHT_BLUE}
          borderRadius="10px"
          mt="2rem"
          marginBottom="1rem"
          w="100%"
          onClick={handleSignup}
        >
          Sign Up
        </VomuxButton>
        <Agreements />
      </Box>
    </Box>
  )
}
