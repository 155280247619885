export const getUuidFromPath = () => {
  if (typeof window !== "undefined") {
    const splitHref = window.location.href.split("/")
    if (splitHref.length < 1) {
      console.log("Invalid interview ID from split.")
      return
    }
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    const idFromPath = splitHref[splitHref.length - 1]
    const isUuid = regexExp.test(idFromPath)
    if (!isUuid) {
      console.log("Invalid interview ID from regex: " + idFromPath)
      return
    }
    console.log(idFromPath)
    return idFromPath
  }
}
