import { useState, useEffect } from "react"
import { getUuidFromPath } from "../utils/path"

export function useUuidFromPath() {
  const [id, setId] = useState(null)
  function listenToPath() {
    if (typeof window !== "undefined") {
      return window.location.href
    }
  }
  useEffect(() => {
    const idFromPath = getUuidFromPath()
    if (!idFromPath) {
      return
    }
    setId(idFromPath)
  }, [listenToPath])

  return id
}
