import { Box, Input } from "@chakra-ui/react"
import React, { useContext, useEffect, useState } from "react"
import { VomuxButton } from "../shared/vomux_button"
import {
  VomuxTextAttention,
  VomuxTextStandard,
  VomuxTextTitle,
} from "../shared/vomux_text"
import SignUpBanner from "../../images/sign_in_comapnies_banner.svg"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { navigate } from "gatsby"
import { ApiContext, UserContext } from "../Root"
import { ApiClient } from "../../services/api"
import validator from "email-validator"
import { useUuidFromPath } from "../../hooks/path"
import { AnalyticsEvents, SessionInviteData } from "../../services/types"
import {
  sendAmplitudeEvent,
  setAmplitudeUserId,
} from "../../services/amplitude"
import { Agreements } from "../shared/agreements"
import { getUuidFromPath } from "../../utils/path"
import { useDelayedAnalyticsEventOnLand } from "../../hooks/analytics"

export const InviteForm: React.FC = props => {
  const api = useContext(ApiContext)
  const user = useContext(UserContext)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [emailError, setEmailError] = useState("")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [sessionInvite, setSessionInvite] =
    useState<SessionInviteData>(undefined)
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.session_invite_landed, {
    session_invite_id: getUuidFromPath(),
  })

  async function userUe() {
    if (user.data.account_id) {
      const session_invite_id = getUuidFromPath()
      if (session_invite_id) {
        const sessionInvite = await api.client.getSessionInviteById(
          session_invite_id
        )
        if (!sessionInvite) {
          alert("Session invite not found")
          return
        }
        if (sessionInvite.accepted_account_id === user.data.account_id) {
          return
        }
        const redeemedInvite = await api.client.redeemSessionInvite({
          session_invite_id,
        })
        if (!redeemedInvite.success) {
          alert(redeemedInvite.message)
          navigate("/home")
          return
        }
        sendAmplitudeEvent(AnalyticsEvents.session_invite_redeemed, {
          session_invite_id,
        })
        navigate(`/interview/${sessionInvite.session_id}`)
        return
      }
    }
  }

  async function ue() {
    const inviteId = getUuidFromPath()
    if (!inviteId) {
      return
    }

    console.log("inviteId", sessionInvite)

    if (!email && sessionInvite?.email) {
      setEmail(sessionInvite.email)
    }

    const sessionInviteRes = await api.client.getSessionInviteById(inviteId)
    if (!sessionInviteRes) {
      return
    }
    setSessionInvite(sessionInviteRes)
    setEmail(sessionInviteRes.email)
  }

  useEffect(() => {
    ue()
  }, [getUuidFromPath])

  useEffect(() => {
    userUe()
  }, [user.data?.account_id])

  const handleSignup = async () => {
    const inviteId = getUuidFromPath()
    if (!firstName) {
      setFirstNameError("First name is required.")
      return
    }
    if (!lastName) {
      setLastNameError("Last name is required")
      return
    }
    if (!validator.validate(email)) {
      setEmailError("Email is invalid.")
      return
    }
    if (!sessionInvite || !inviteId) {
      console.log("No session")
      return
    }

    sendAmplitudeEvent(AnalyticsEvents.session_invite_signup_attempted, {
      email,
      first_name: firstName,
      last_name: lastName,
      session_invite_id: inviteId,
    })

    const signupRes = await api.client.createAccountWithSessionInvite({
      first_name: firstName,
      last_name: lastName,
      email,
      account_password: password,
      session_invite_id: inviteId,
    })

    if (!signupRes || !signupRes.data?.token) {
      alert("issue signing up")
      sendAmplitudeEvent(AnalyticsEvents.session_invite_signup_failed, {
        email,
        first_name: firstName,
        last_name: lastName,
        session_invite_id: inviteId,
      })
      return
    }
    if (typeof window !== "undefined") {
      window.localStorage.setItem("token", signupRes.data.token)
    }
    sendAmplitudeEvent(AnalyticsEvents.session_invite_signup_succeeded, {
      account_id: signupRes.created_id,
      email,
      first_name: firstName,
      last_name: lastName,
      session_invite_id: inviteId,
    })
    api.setApi(new ApiClient(signupRes.data.token))
    user.setUser({
      account_id: signupRes.created_id,
      email: email,
      first_name: firstName,
      last_name: lastName,
      is_confirmed: false,
      created_at: new Date(),
    })
    setAmplitudeUserId(signupRes.created_id)
    navigate(`/interview/${sessionInvite.session_id}`)
  }

  return (
    <Box
      zIndex="2"
      height={{ base: "75%", md: "85%" }}
      width={{ base: "80%", md: "60%", lg: "40%" }}
      borderRadius="10px"
      backgroundColor="white"
      boxShadow="0px 10px 8px #bbb"
      display="flex"
      justifyContent="center"
      marginTop={{ base: "6rem", md: "0" }}
    >
      <Box
        width="80%"
        alignItems="center"
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        marginTop="3rem"
      >
        <VomuxTextTitle marginBottom="2rem">
          Welcome to your interview
        </VomuxTextTitle>
        <Input
          padding=".5rem"
          placeholder="First Name"
          borderRadius="10px"
          width="100%"
          value={firstName}
          onChange={e => {
            setFirstNameError("")
            setFirstName(e.target.value)
          }}
        ></Input>
        <Input
          padding=".5rem"
          placeholder="Last Name"
          borderRadius="10px"
          width="100%"
          value={lastName}
          onChange={e => {
            setLastNameError("")
            setLastName(e.target.value)
          }}
        ></Input>
        <Input
          padding=".5rem"
          placeholder="Email"
          borderRadius="10px"
          width="100%"
          value={email}
          onChange={e => {
            setEmailError("")
            setEmail(e.target.value)
          }}
        ></Input>
        <Input
          padding=".5rem"
          placeholder="Password"
          borderRadius="10px"
          width="100%"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        ></Input>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={emailError ? "flex" : "none"}
        >
          Please enter a valid email.
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={firstNameError ? "flex" : "none"}
        >
          Please enter a first name.
        </VomuxTextStandard>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={lastNameError ? "flex" : "none"}
        >
          Please enter a last name.
        </VomuxTextStandard>
        <VomuxButton
          borderColor={COLORS_PRIMARY.LIGHT_BLUE}
          padding=".5rem"
          backgroundColor={COLORS_PRIMARY.LIGHT_BLUE}
          borderRadius="10px"
          marginBottom="1rem"
          w="100%"
          onClick={handleSignup}
        >
          Sign Up
        </VomuxButton>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
        >
          <VomuxTextStandard fontSize="1rem">
            Have an account?
          </VomuxTextStandard>
          <VomuxTextAttention
            onClick={() => {
              navigate("/login")
            }}
            cursor="pointer"
            fontSize="1rem"
          >
            Sign In
          </VomuxTextAttention>
        </Box>
        <Agreements />
      </Box>
    </Box>
  )
}
