import { Box, Image } from "@chakra-ui/react"
import React, { useContext, useEffect } from "react"
import SignUpBackground from "../../images/sign_up_background.svg"
import SignUpBackgroundDots from "../../images/sign_in_background_dots.svg"
import SignUpBackgroundDotsLight from "../../images/sign_in_background_dots_light.svg"
import VomuxLogo from "../../images/vomux_logo.svg"
import SignUpBackgroundMobile from "../../images/sign_up_background_mobile.svg"
import { InviteForm } from "./invite_form"
import { SignUpForm } from "./sign_up_form"
import { useUuidFromPath } from "../../hooks/path"

interface SignUpProps {}

export const SignUp: React.FC<SignUpProps> = props => {
  const inviteId = useUuidFromPath()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundPosition={{ base: "center", md: "inherit" }}
      backgroundImage={{ base: SignUpBackgroundMobile, md: SignUpBackground }}
      backgroundRepeat="no-repeat"
    >
      <Image
        position="absolute"
        top="26rem"
        left={{ base: "2rem", md: "10rem" }}
        src={SignUpBackgroundDots}
      />
      <Image
        position="absolute"
        top="8rem"
        right="1rem"
        src={SignUpBackgroundDotsLight}
      />
      <Image src={VomuxLogo} position="fixed" top="3rem" left="3rem" />
      {!!inviteId ? <InviteForm /> : <SignUpForm />}
    </Box>
  )
}
