import { Box } from "@chakra-ui/react"
import React from "react"
import Helmet from "react-helmet"
import Seo from "../components/seo"
import { SignUp } from "../components/signup/signup"

const SignUpPage = () => (
  <Box>
    <Seo title="Sign Up" />
    <SignUp />
  </Box>
)

export default SignUpPage
